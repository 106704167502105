import React from 'react';

import Layout from '../../components/Layout';
import CourseFeatures from '../../components/CourseFeatures';
import pic1 from '../../assets/images/courses/aws-certified-dev.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AwsDevAssociateTrng = () => (
  <Layout>
    <div id="main" >
      <div className="inner">
        <article className="box"> 
        <h1>AWS Certified Developer Associate Training</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>
        </article>
        <article className="box">
        
        
        <Container>
          <div className="inner">
          <p>
            Our comprehensive <b>AWS Certified Developer Associate </b> Training Program  was developed to provide 
            you with the requisite knowledge to not only pass the AWS Certified Developer Associate certification exam but also gain 
            the hands-on experience required to become a qualified AWS Developer working in real-world AWS environments.
          </p>
          </div>
          <article>
            <p>
              With a balanced mix of theoretical concepts and hands-on exercises, you will learn how to develop, 
              deploy and debug cloud-based applications using AWS. 
              We promise to equip you with the necessary skills that will enable you to confidently 
              work with the AWS platform.This is an intensive 16 Week  program followed by an opportunity for a 12 – week co-op program.
            </p><br />
          </article>
        </Container>
        <Container>
          <Row>
            <Col><h4>What this course offers</h4>
              <ListGroup>
                    <ListGroup.Item><span className="icon fa-check-square-o">  Familiarity with the AWS platform from a developer perspective. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  The knowledge to apply the right AWS services for your AWS projects</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  How to prepare for the AWS exam AND how to prepare for the real world </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Structured rigourous learning plan with practical sessions </span></ListGroup.Item> 
              </ListGroup>
            </Col>
          </Row><br />
          <Row>
            <Col><h4>Who this course is for </h4>
              <ListGroup>
                    <ListGroup.Item><span className="icon fa-check-square-o">  This course is for students fresh out of college as well as experienced developers. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  AWS absolute beginners. No prior AWS experience necessary </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Programmers who are not feeling confident about their existing AWS skills</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  People interested in hosting highly scaleable, fault tolerant applications on the AWS cloud. </span></ListGroup.Item> 
              </ListGroup>
            </Col>
          </Row>
        </Container><br />
        <CourseFeatures /><br />
        

        <Accordion defaultActiveKey="0">
          <Card bg="info" text="black">
            <Card.Header as="h5">COURSE OUTLINE</Card.Header>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span className="icon major style1 h5 fa-plus-circle" >    1.	Course Introduction (Week 1)</span>         
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>1.1	What is Cloud Computing </ListGroup.Item>
                <ListGroup.Item>1.2	AWS Fundamentals </ListGroup.Item>
                <ListGroup.Item>1.3	AWS Global Infrastructure </ListGroup.Item>
                <ListGroup.Item>1.4	AWS Platform and Services	</ListGroup.Item>
                <ListGroup.Item>1.5	AWS Regions and Availability Zones </ListGroup.Item>
                <ListGroup.Item>1.6	Learning Paths for Training and Certification </ListGroup.Item>
                <ListGroup.Item>1.7	How To Find The Resources For This Course.</ListGroup.Item>
                <ListGroup.Item>1.8	Most important Concepts and Topics for the AWS CDA Exam </ListGroup.Item>
                <ListGroup.Item>1.9	Exam Blue Print  </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span className="icon major style1 h5 fa-plus-circle" >    2.	Core Services (Week 2, Week 3)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>2.1	Identity and Access Management 
                  <ListGroup>
                    <ListGroup.Item>2.1.1	IAM 101</ListGroup.Item>
                    <ListGroup.Item>2.1.2	AWS Key Management Service (KMS) and Encryption</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>2.2	AWS VPC Networking
                  <ListGroup>
                    <ListGroup.Item>2.2.1	VPC Essentials</ListGroup.Item>
                    <ListGroup.Item>2.2.2	VPC Networking Basics</ListGroup.Item>
                    <ListGroup.Item>2.2.3	VPC Security Basics</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>2.3	AWS Load Balancing
                  <ListGroup>
                    <ListGroup.Item>2.3.1	Application Load Balancers</ListGroup.Item>
                    <ListGroup.Item>2.3.2	Network Load Balancers</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>2.4	AWS Elastic Container Service</ListGroup.Item>
                <ListGroup.Item>2.5	AWS Elastic Beanstalk</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span className="icon major style1 h5 fa-plus-circle" >    3.	AWS Server Based Compute Services EC2 (Week 4)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>3.1	EC2 101</ListGroup.Item>
                <ListGroup.Item>3.2	Launching EC2 Instances</ListGroup.Item>
                <ListGroup.Item>3.3	Summary of EC2 Services</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <span className="icon major style1 h5 fa-plus-circle" >    4.	AWS Storage Services (Week 5, Week 6) </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>4.1	Amazon S3</ListGroup.Item>
                <ListGroup.Item>4.2	AWS CloudFront</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <span className="icon major style1 h5 fa-plus-circle" >    5.	AWS Database Services (Week 7, Week 8)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>5.1	DynamoDB</ListGroup.Item>
                <ListGroup.Item>5.2	Other AWS Database Services</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="5">
              <span className="icon major style1 h5 fa-plus-circle" >    6.	AWS Serverless Based Compute Services (Week 9, Week 10)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>6.1	Serverless 101</ListGroup.Item>
                <ListGroup.Item>6.2	AWS Lambda</ListGroup.Item>
                <ListGroup.Item>6.3	Lambda Essentials</ListGroup.Item>
                <ListGroup.Item>6.4	Lambda Functions and Events</ListGroup.Item>
                <ListGroup.Item>6.5	AWS Step Functions</ListGroup.Item>
                <ListGroup.Item>6.6	API Gateway </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              <span className="icon major style1 h5 fa-plus-circle" >    7.	AWS Application Services (Week 11, Week 12) </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>7.1	Amazon Simple Notification Service (SNS)</ListGroup.Item>
                <ListGroup.Item>7.2	Amazon Simple Queue Service (SQS)</ListGroup.Item>
                <ListGroup.Item>7.3	Amazon Simple Workflow Service (SWF)</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              <span className="icon major style1 h5 fa-plus-circle" >   8.	AWS Management Tools (Week 13, Week 14) </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>8.1	CloudWatch</ListGroup.Item>
                <ListGroup.Item>8.2	Amazon CloudFormation</ListGroup.Item>
                <ListGroup.Item>8.3	CloudWatch Vs CloudTrail</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="8">
              <span className="icon major style1 h5 fa-plus-circle" >    9.	AWS Developer Tools (Week 15)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item>9.1	AWS Code Commit</ListGroup.Item>
                  <ListGroup.Item>9.2	AWS Code Build</ListGroup.Item>
                  <ListGroup.Item>9.3	AWS Code Deploy</ListGroup.Item>
                  <ListGroup.Item>9.4	AWS Code Pipeline</ListGroup.Item>
                  <ListGroup.Item>9.5	AWS Code Star</ListGroup.Item>
                  <ListGroup.Item>9.6	X-Ray  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="8">
              <span className="icon major style1 h5 fa-plus-circle" >    10.	 AWS Developer Associate Exam Prep (Week 16)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item>10.1	Create and Deploy a Real World Application</ListGroup.Item>
                  <ListGroup.Item>10.2	Topics that You Won’t Find in the AWS CDA Exam</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        </article>
      </div>
    </div>
  </Layout>
);

export default AwsDevAssociateTrng;
